import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";
import router from "@/router";

export default function useUserCourseList() {
  // Use toast
  const toast = useToast();

  const refUserCourseTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "approved_bit", label: "Approved", sortable: true },
    { key: "created_at", label: "date", sortable: true },
    { key: "topic", label: "topic" },
    { key: "course_organization_name", label: "Organiser" },
    { key: "ethics_compliance", label: "ethics & compliance topic related" },
    { key: "relevant_topic", label: "Topics directly relevant to the regulated activities" },
    { key: "counted_hours", label: "counted of cpt" },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalRecords = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserCourseTable.value
      ? refUserCourseTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    };
  });

  const refetchData = () => {
    console.log("refUserCourseTable", refUserCourseTable);
    refUserCourseTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchCourseRecord = (ctx, callback) => {
    store
      .dispatch("app-user/fetchCourseRecord", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        console.log(response);
        const { staff_records, total } = response.data;
        callback(staff_records);
        totalRecords.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const yesNoOptions = [
    {
      id: 1,
      name: 'Yes'
    },
    {
      id: 0,
      name: 'No'
    }
  ];



  return {
    fetchCourseRecord,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserCourseTable,
    ability,
    refetchData,
  };
}
