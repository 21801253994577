<template>
  <b-modal
    id="modal-user-filter"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title="FILTER"
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-footer
    no-close-on-backdrop
    size="lg"
    hide-header-close
  >
    <feather-icon
      @click="hide"
      style="position: absolute; right: 20px; top: -40px"
      size="18"
      icon="XIcon"
    />
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-form class="mt-1" @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Date From:"
              label-for="fliter_date_from"
              label-cols-md="3"
            >
              <flat-pickr
                id="fliter_date_from"
                :value="update.dateFromFilter"
                class="form-control"
                :config="flatDateConfig"
                placeholder="YYYY-MM-DD"
                @input="(val) => (update.dateFromFilter = val)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Date To:"
              label-for="fliter_date_to"
              label-cols-md="3"
            >
              <flat-pickr
                id="fliter_date_to"
                :value="update.dateToFilter"
                class="form-control"
                :config="flatDateConfig"
                placeholder="YYYY-MM-DD"
                @input="(val) => (update.dateToFilter = val)"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex mt-2 justify-content-end">
          <!-- type="reset" -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="update = {}"
            class="mr-1"
          >
            Reset
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-danger"
            class="mr-1"
            @click="hide"
          >
            Cancel
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
          >
            <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
            Submit
          </b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-course-filter");
    },
    onSubmit() {
      this.$emit("update:dateFromFilter", this.update.dateFromFilter);
      this.$emit("update:dateToFilter", this.update.dateToFilter);
      this.hide();
    },
  },
  props: ["dateFromFilter", "dateToFilter"],
  data() {
    return {
      loading: false,
      update: {
        dateFromFilter: "",
        dateToFilter: "",
      },
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
