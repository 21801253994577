<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" v-if="!userData">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }"> User List </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" v-if="userData">
          <user-view-user-info-card
            :user-data="userData"
            :course-data="courseData"
            :total-hours="totalHours"
            @refetch-data="refetchData"
          />
        </b-col>
      </b-row>

      <!-- <b-col cols="12" lg="6">
        <user-view-user-permissions-card />
      </b-col> -->
      <!-- </b-row> -->
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BAlert,
} from "bootstrap-vue";

import userStoreModule from "../userStoreModule";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";

import UserViewUserPermissionsCard from "./UserViewUserPermissionsCard.vue";
import useUserCourseList from "./useUserCourseList";

export default {
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPermissionsCard,
  },
  setup() {
    const userData = ref(null);

    const courseData = ref([]);

    const totalHours = ref(0);

    const USER_APP_STORE_MODULE_NAME = "app-user";

    const refetchData = () => {
      console.log("refUserCourseTable", refUserCourseTable);
      // refUserCourseTable.value.refresh();
      fetchCourseData();
    };

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    onMounted(() => {
      fetchCourseData();
    });

    store
      .dispatch("app-user/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        userData.value = response.data.user[0];
      })
      .catch((error) => {
        userData.value = undefined;
      });

    const fetchCourseData = () => {
      store
        .dispatch("app-user/fetchCourseRecord", {
          user_id: router.currentRoute.params.id,
        })
        .then((response) => {
          const currYear = new Date().getFullYear();
          console.log(currYear);
          const currentResponse = response.data.staff_records.filter(
            (x) => x.course_year == currYear
          );
          console.log("response fetching course record list", response);
          courseData.value = currentResponse;
          totalHours.value = Number(response.data.total_hours);
        })
        .catch((error) => {
          console.log("error fetching course record list", error);
          courseData.value = [];
          totalHours.value = 0;
        });
    };

    const {
      fetchCourseRecord,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserCourseTable,
      // refetchData,
      ability,
    } = useUserCourseList();

    return {
      userData,
      courseData,
      totalHours,
      refUserCourseTable,
      fetchCourseRecord,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      fetchCourseData,
      ability,
    };
  },
};
</script>

<style></style>
