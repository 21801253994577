<template>
  <div>
    <!-- <modal-course-filter :date-from-filter.sync="dateFromFilter" :date-to-filter.sync="dateToFilter" /> -->
    <div>

      <b-sidebar id="sidebar-right" bg-variant="white" right backdrop shadow no-header #default="{ hide }">
        <div class=" d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">Approve Training Record</h5>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <b-form class="p-2" @submit.prevent="onSubmit(approveTemplate.id)" @reset.prevent="resetForm">

            <!-- Title -->
            <!-- <b-form-group label="Approve Training">
        <b-form-input type="text" placeholder="" v-model="courseData.approved_bit" />
      </b-form-group> -->

            <b-form-group v-if="ability.can('update', 'course')" label="Approve Training" label-for="approved_bit">
              <v-select v-model="approveTemplate.approved_bit" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="approved_options" label="name" :reduce="(option) => option.id" :clearable="false"
                placeholder="" />
            </b-form-group>

            <b-form-group v-else label="Approve Training" label-for="approved_bit">
              <v-select v-model="approveTemplate.approved_bit" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="approved_options" label="name" :reduce="(option) => option.id" :clearable="false" placeholder=""
                disabled />
            </b-form-group>

            <b-form-group label="Topic" label-for="topic">
              <b-form-input id="topic" v-model="approveTemplate.topic" placeholder="Enter Topic" />
            </b-form-group>

            <b-form-group label="Hours" label-for="counted_hours">
              <b-form-input id="counted_hours" v-model="approveTemplate.counted_hours" placeholder="Enter Hours" />
            </b-form-group>

            <b-form-group label="Ethics & Compliance Topic" label-for="ethics_compliance">
              <!-- <b-form-input id="ethics_compliance" v-model="approveTemplate.ethics_compliance" placeholder="Ethics % Compliance Topic" /> -->
              <v-select v-model="approveTemplate.ethics_compliance" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="e_c" label="name" :reduce="(option) => option.id" :clearable="false" placeholder="" />
            </b-form-group>

            <b-form-group v-if="approveTemplate.authority_id == 1"
              label="Topics directly relevant to the regulated activities" label-for="relevant_topic">
              <!-- <b-form-input id="relevant_topic" v-model="approveTemplate.relevant_topic" placeholder="Ethics % Compliance Topic" /> -->
              <v-select v-model="approveTemplate.relevant_topic" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="e_c" label="name" :reduce="(option) => option.id" :clearable="false" placeholder="" />
            </b-form-group>

            <b-form-group label="Remark" label-for="remark">
              <b-form-input id="remark" v-model="approveTemplate.remark" placeholder="Enter Remark" />
            </b-form-group>

            <div class="d-flex mt-2">
              <b-button v-if="ability.can('update', 'course')" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary" class="mr-2" type="submit">
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                Submit
              </b-button>
              <b-button v-else v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                disabled>
                <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="" class="mr-2" @click="hide">
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </b-sidebar>
    </div>

    <div>
      <b-card no-body class="p-1 px-2">
        <!-- User Info: Top col -->
        <div class="d-flex flex-wrap align-items-center justify-content-between">
          <h4 class="mb-0 ml-0 color4 font-weight-bolder text-nowrap">
            USER INFORMATION
          </h4>
          <div class="d-flex detail-top-b-list flex-wrap justify-content-end">
            <b-button class="px-3" v-if="ability.can('update', 'user')"
              :to="{ name: 'users-edit', params: { id: userData.id } }" variant="primary">
              <feather-icon class="mr-1" size="14" icon="Edit2Icon" />Edit
            </b-button>
            <b-button class="px-3" v-if="ability.can('delete', 'user')" variant="outline-danger" @click="deleteUser">
              <feather-icon class="mr-1" size="14" icon="Trash2Icon" />Delete
            </b-button>
          </div>
        </div>
        <hr />
        <!-- User Table: Left col -->
        <b-row class="">
          <b-col class="d-flex flex-wrap" style="padding-top: 15px;">
            <b-col cols="12" md="4" class="d-flex justify-content-start">
              <div>
                <h3 class="font-weight-bolder color4">{{ userData.name }}</h3>
                <h4 class="font-weight-bolder color4">{{ userData.name_cn }}</h4>
                <h6 v-for="role in userData.roles" :key="`role_${role.id}`">{{ role.name }} <span
                    v-if="userData.roles.length > 1">/</span></h6>
                <div v-for="team in userData.teams" :key="`team_${team.id}`">
                  <b-badge v-bind:style="{ 'background-color': team.background_color, 'color': team.font_color }">{{
                    team.en_name
                  }}
                  </b-badge>
                </div>
                <br />
                <p>Joining Date: {{ userData.onboard }}</p>
                <p>Working Visa: {{ userData.working_visa == 0 ? "N/A" : "Yes" }}</p>
              </div>
            </b-col>
            <hr class="w-100 d-md-none" />
            <b-col cols="12" md="4" class="d-flex justify-content-start">
              <div>
                <div v-for="license, index in userData.user_licenses" :key="`license_number_${license.id}`">
                  <p>License Number {{ index + 1 }}: {{ license.license_number }} ({{ license.authority_name }})</p>
                </div>
                <div class="d-flex">
                  <div cols="6">
                    <p>License Type: </p>
                  </div>
                  <div cols="6">
                    <p v-for="type in userData.license_types" :key="`type_${type.id}`">{{ type.authority_name }} ({{
                      type.license_type
                    }})</p>
                  </div>
                </div>
                <div>
                  <p class="pointed-li">{{ userData.employer_company.company_name }}</p>
                </div>
              </div>
            </b-col>
            <hr class="w-100 d-md-none" />
            <b-col cols="12" md="4" class="d-flex justify-content-start flex-column">
              <div class="parent">
                <div title="User Status" class="d-flex align-items-center">
                  <font-awesome-icon icon="fa-solid fa-user" />
                  <p class="m-0">{{
                    userData.user_statuses_id == 1 ? 'Active' : userData.user_statuses_id == 2 ?
                    'Inactive'
                    : userData.user_statuses_id == 3 ? 'Resigned' : userData.user_statuses_id == 4 ? 'Fired' :
                      'Retired'
                  }}
                  </p>
                </div>
                <div title="HK ID" class="d-flex align-items-center">
                  <!-- <feather-icon icon="UserIcon" size="12" /> -->
                  <font-awesome-icon icon="fa-solid fa-id-badge" />
                  <p class="m-0">{{ userData.hkid }}</p>
                </div>
                <div title="Passport Number and Expiry date" class="d-flex align-items-center">
                  <!-- <feather-icon icon="UserIcon" size="12" /> -->
                  <font-awesome-icon icon="fa-solid fa-passport" />
                  <p class="m-0">{{ userData.passport_number }} ({{ userData.passport_expiry }})</p>
                </div>
                <div title="Date of Birth" class="d-flex align-items-center">
                  <!-- <feather-icon icon="UserIcon" size="12" /> -->
                  <font-awesome-icon icon="fa-solid fa-cake-candles" />
                  <p class="m-0">{{ userData.date_of_birth }}</p>
                </div>
                <div title="email" class="d-flex align-items-center">
                  <!-- <feather-icon icon="UserIcon" size="12" /> -->
                  <font-awesome-icon icon="fa-solid fa-envelope" />
                  <p class="m-0">{{ userData.email }}</p>
                </div>
                <div title="Contact Number" class="d-flex align-items-center">
                  <!-- <feather-icon icon="UserIcon" size="12" /> -->
                  <font-awesome-icon icon="fa-solid fa-phone" />
                  <p class="m-0">{{ userData.contact_number }}</p>
                </div>
              </div>
            </b-col>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div>
      <b-row class="">
        <b-col lg="4" md="6">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" class="">
                <div class="d-flex">
                  <!-- <feather-icon icon="UserIcon" size="19" /> -->
                  <h3 class="mb-0 ml-50 color4 font-weight-bolder">TRAINING PROGRESS</h3>
                </div>
                <hr />
                <div class="form-col-select mb-1">
                  <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :clearable="false" v-model="year"
                    :options="yearOptions" label="course_year" class="" @input="filterYear(year)" />

                  <feather-icon size="18" icon="ChevronDownIcon" />
                </div>
                <div>
                  <b-tabs class="tabs-primary" pills>
                    <b-tab v-for="authority in licenseAuthorityOptionsTab" :key="authority.id + authority.authority_name"
                      :title="authority.training_name" :value="authority.id" @click="filterName(authority.training_name)">

                      <b-row class="" v-if="selectedYear !== undefined">
                        <b-col cols="8">
                          <strong class="color1">Completed Hours: </strong>
                        </b-col>
                        <b-col cols="4">
                          <p class="">{{ completedHours != undefined ? completedHours : "N/A" }}</p>
                        </b-col>

                        <b-col cols="8">
                          <strong class="color1">Outstanding Hours:</strong>
                        </b-col>
                        <b-col cols="4">
                          <p class="">{{ min_hours != undefined ? min_hours - completedHours < 0 ? 0 : min_hours -
                            completedHours : min_hours }}</p>
                        </b-col>

                        <b-col cols="8">
                          <strong class="color1">Ethics & Compliance Topic:</strong>
                        </b-col>
                        <b-col cols="4">
                          <p class="" v-if="ethicsBool">Complete</p>
                          <p class="redColor" v-else>Incomplete</p>
                        </b-col>

                        <b-col v-if="authority.training_name == 'CPT'" cols="8">
                          <strong class="color1">Topics directly relevant to the regulated activities:</strong>
                        </b-col>
                        <b-col v-if="authority.training_name == 'CPT'" cols="4">
                          <p class="">{{ relevantTopicBool }}</p>
                        </b-col>
                        <!-- <b-col v-if="authority.training_name == 'CPT'" cols="4">
                          <p class="" v-if="relevantTopicBool">Complete</p>
                          <p class="redColor" v-else>Incomplete</p>
                        </b-col> -->
                      </b-row>
                      <b-row v-else>
                        <h3>No record found</h3>
                      </b-row>
                    </b-tab>
                  </b-tabs>

                </div>

              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="8" md="6">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" class="">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <h3 class="mb-0 ml-50 color4 font-weight-bolder">COURSE RECORD</h3>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mr-2 mt-1 mt-sm-0">
                      <!-- <label for="filter-date-from" class="mr-2">From:</label> -->
                      <flat-pickr id="filter-date-from" v-model="dateFrom" class="form-control"
                        :config="flatDateConfig" placeholder="Date From" />
                    </div>
                    <div>
                      <!-- <label for="filter-date-to" class="ml-2 mr-2">To:</label> -->
                      <flat-pickr id="filter-date-to" v-model="dateTo" class="form-control"
                        :config="flatDateConfig" placeholder="Date To" />
                    </div>
                  </div>
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-if="ability.can('update', 'course')"
                    v-b-modal.modal-xl variant="primary">
                    <font-awesome-icon icon="fa-solid fa-plus" style="padding-right: 10px;" />
                    Add New Record
                  </b-button>
                </div>
                <hr />

                <!-- :items="fetchBanks"
                      :fields="tableBankRMColumns"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="isSortDirDesc"
                      primary-key="id" -->
                <b-table sticky-header ref="refUserCourseTable" class="position-relative" responsive show-empty
                  empty-text="No matching records found" :items="filteredData" :fields="tableColumns">

                  <template #cell(approved_bit)="data">
                    <div v-if="data.item.approved_bit == 0" size="18" class="mr-50 text-secondary">Pending Approval
                    </div>
                    <div v-if="data.item.approved_bit == 1" size="18" class="mr-50 text-success">&#10003</div>
                    <div v-if="data.item.approved_bit == -1" size="18" class="mr-50 text-danger">&#x2717</div>

                  </template>

                  <template #cell(course_date)="data">
                    <div v-if="data.item.course_date != null">{{ new Date(data.item.course_date).getDate() }}/{{
                      new
                        Date(data.item.course_date).getMonth() + 1
                    }}<br /></div>
                  </template>

                  <template #cell(topic)="data">
                    <div>
                      {{ data.item.topic }} - {{ data.item.training_name }}</div>
                  </template>

                  <template #cell(course_organization_name)="data">
                    <div>
                      {{ data.item.course_organization_name }}</div>
                  </template>

                  <template #cell(ethics_compliance)="data">
                    <div v-if="data.item.ethics_compliance == 0">
                      No</div>
                    <div v-else-if="data.item.ethics_compliance == 1">Yes</div>
                    <div v-else>N/A</div>
                  </template>

                  <template #cell(relevant_topic)="data">
                    <div v-if="data.item.relevant_topic == 0">
                      No</div>
                    <div v-else-if="data.item.relevant_topic == 1">Yes</div>
                    <div v-else>N/A</div>
                  </template>

                  <template #cell(counted_hours)="data">
                    <div>
                      {{ data.item.counted_hours }}</div>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <b-dropdown v-if="ability.can('update', 'course')" variant="link" no-caret
                      :right="$store.state.appConfig.isRTL">
                      <!-- v-if="ability.can('update','users')" -->
                      <template #button-content>
                        <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
                      </template>
                      <b-dropdown-item @click="handleEdit(data.item)" v-b-toggle.sidebar-right>
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Edit</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </b-col>
              <b-col>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <h3 class="mb-0 ml-50 color4 font-weight-bolder">TOTAL</h3>
                  <h3 class="mb-0 ml-50 color4 font-weight-bolder">{{ totalHours }}</h3>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <div>

          <b-modal id="modal-xl" v-model="show" centered size="xl" hide-footer :title="addrecordmodeltitle">
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
              <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
                <b-form @submit.prevent="handleSubmit(submitModal)" @reset.prevent="resetForm">
                  <b-row class="m-2">
                    <!-- Label: Record For -->
                    <b-col cols="12">
                      <validation-provider #default="validationContext" name="Record For" rules="required">
                        <b-form-group label="Record For:" label-for="authority_id" label-cols-md="4" class="required">
                          <!-- <b-form-input v-model="newRecord.authority" id="authority"
                            placeholder="Enter speaker or authority" /> -->
                          <div class="form-col-select">
                            <v-select v-model="newRecord.authority_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="licenseAuthorityOptions" :clearable="false" label="authority_name"
                              :reduce="(option) => option.id" placeholder="SFC/IA" />

                            <feather-icon size="18" icon="ChevronDownIcon" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </b-form-group>

                      </validation-provider>
                    </b-col>
                    <!-- Field: Date -->
                    <b-col cols="12">
                      <validation-provider #default="validationContext" name="Date" rules="required">
                        <b-form-group label="Date:" label-for="course_date" label-cols-md="4" class="required">
                          <!-- <flat-pickr v-model="newRecord.course_date" class="form-control"
                            :config="flatDateConfig" placeholder="YYYY-MM-DD"></flat-pickr> -->
                          <flat-pickr v-model="newRecord.course_date" class="form-control"
                            :config="flatDateConfig" placeholder="YYYY-MM-DD" name="date" />
                          <!-- <flat-pickr v-model="newRecord.course_date" /> -->
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>

                      </validation-provider>
                    </b-col>
                    <!-- Label: Staff
                    <b-col cols="12">
                      <validation-provider #default="validationContext" name="Staff" rules="required">
                        <b-form-group class="required" label-for="staff_id" label="Staff:" label-cols-md="4">
                          <div class="form-col-select">
                            <v-select id="staff_id" v-model="newRecord.user_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="userOptions"
                              :clearable="false" label="name" :reduce="(option) => option.id" placeholder="Enter Staff"
                              multiple />
                            <feather-icon size="18" icon="ChevronDownIcon" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </b-col> -->
                    <!-- Label: Topic -->
                    <b-col cols="12">
                      <validation-provider #default="validationContext" name="Topic" rules="required">
                        <b-form-group label="Topic" label-for="topic" label-cols-md="4" class="required">
                          <b-form-input v-model="newRecord.topic" id="topic" placeholder="Enter Topic"
                            :state="getValidationState(validationContext)" />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Label: Organiser -->
                    <b-col cols="12">
                      <validation-provider #default="validationContext" name="Organiser" rules="required">
                        <b-form-group class="required" label="Organiser:" label-for="course_organization_id"
                          label-cols-md="4">
                          <!-- <b-form-input v-model="newRecord.course_organization_id" id="course_organization_id"
                          placeholder="Enter speaker or course_organization_id" /> -->
                          <div class="form-col-select">
                            <v-select v-model="newRecord.course_organization_id"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="organizationOptions"
                              :clearable="false" label="name" :reduce="(option) => option.id"
                              placeholder="Enter Organiser" />
                            <feather-icon size="18" icon="ChevronDownIcon" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Label: Ethics & Compliance Topic Related -->
                    <b-col cols="12">

                      <validation-provider #default="validationContext" name="Ethics & Compliance Topic Related"
                        rules="required">
                        <b-form-group class="required" label-for="ethics_compliance"
                          label="Ethics & Compliance Topic Related:" label-cols-md="4">
                          <div class="form-col-select">
                            <v-select id="ethics_compliance" v-model="newRecord.ethics_compliance"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="yesNoOptions"
                              :clearable="false" label="name" :reduce="(option) => option.id" placeholder="Yes/No" />
                            <feather-icon size="18" icon="ChevronDownIcon" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- Label: Topics Directly Relevant to the Regulated Activities -->
                    <b-col v-if="newRecord.authority_id == 1 || newRecord.authority_id == 'b'" cols="12">

                      <validation-provider #default="validationContext"
                        name="Topics Directly Relevant to the Regulated Activities" rules="required">
                        <b-form-group class="required" label-for="relevant_topic"
                          label="Topics Directly Relevant to the Regulated Activities:" label-cols-md="4">
                          <div class="form-col-select">
                            <v-select id="relevant_topic" v-model="newRecord.relevant_topic"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="yesNoOptions"
                              :clearable="false" label="name" :reduce="(option) => option.id" placeholder="Yes/No" />
                            <feather-icon size="18" icon="ChevronDownIcon" />
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Label: Counted of CPT -->
                    <b-col cols="12">
                      <validation-provider #default="validationContext" name="Counted of CPT" rules="required">
                        <b-form-group class="required" label="Counted of CPT:" label-for="counted_hours"
                          label-cols-md="4">
                          <b-form-input v-model="newRecord.counted_hours" id="counted_hours"
                            placeholder="Enter number of hours" :state="getValidationState(validationContext)" />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Label: Certificate -->
                    <b-col cols="12">
                      <b-form-group label="Certificate:" label-for="file" label-cols-md="4">
                        <!-- <b-form-file v-model="newRecord.file" id="file"
                          placeholder="Select a file or drag and drop here" drop-placeholder="Drop file here..."
                          multiple /> -->
                        <el-upload class="avatar-uploader" list-type="picture-card" ref="upload_image" :action="uploadUrl"
                          :headers="headerObj" name="image_files" multiple :limit="10" :data="newRecord"
                          :on-change="onChange" :on-remove="onChange" :auto-upload="false">
                          <i class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <!-- accept=".jpg, .png, .gif, .pdf, .jpeg" -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="modal-bottom">
                    <!-- @click="resetForm" -->
                    <b-button type="reset" v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary"
                      class="mr-1">
                      Reset
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="hideModal" variant="outline-danger"
                      class="mr-1">
                      Cancel
                    </b-button>
                    <!-- @click="submitModal" -->
                    <b-button type="submit" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1">
                      <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                      Submit
                    </b-button>
                  </div>
                </b-form>
              </b-overlay>
            </validation-observer>
          </b-modal>

        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BTab,
  BTabs,
  BTable,
  VBToggle,
  BForm,
  BFormGroup,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BFormInvalidFeedback,
  BOverlay,
  BFormInput,
  BModal,
  VBModal,
  BFormFile,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "animate.css";
import { avatarText } from "@core/utils/filter";
import useUsersList from "../users-list/useUsersList";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, alphaNum, email } from "@validations";
import ModalCourseFilter from "../modal/ModalCourseFilter.vue";

import ability from "@/libs/acl/ability";
import router from "@/router";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BTab,
    BTabs,
    BTable,
    vSelect,
    BForm,
    BFormGroup,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BOverlay,
    BFormInput,
    BButton,
    BModal,
    BFormInvalidFeedback,
    flatPickr,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    ModalCourseFilter
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      approveTemplate: "",
      show: false,
      // title: 1,
      dateFrom: null,
      dateTo: null,
    };
  },
  methods: {
    onChange(file, fileLists) {
      this.newRecord.file = [];
      fileLists.forEach((item) => {
        this.newRecord.file.push(item.raw);
      });
      console.log("image_files", this.newRecord.file);
    },
    checking(e) {
      this.trainingName = e;
      console.log(this.trainingName);
      this.filterName(e);
    },
    resetForm() {
      this.newRecord = {
        id: 0,
        authority_id: "",
        course_date: null,
        user_id: [userData.id],
        topic: "",
        course_organization_id: "",
        ethics_compliance: "",
        relevant_topic: "",
        counted_hours: "",
        file: [],
      };
    },
    showModal() {
      this.show = true;
    },
    hideModal() {
      this.show = false;
    },
    deleteUser(id) {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-user/deleteUser", {
              id: router.currentRoute.params.id,
            })
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$router.replace("/users/list");
              this.$emit("refetch-data");
              // :to="{ name: 'users-edit', params: { id: userData.id } }"
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    submitModal() {
      console.log(this.newRecord);
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let array = this.newRecord.course_date.split("-");
          // this.loading = false;
          let formData = new FormData();

          this.newRecord.user_id.forEach((item) => {
            formData.append("user_id[]", item);
          });
          this.newRecord.file.forEach((item) => {
            formData.append("file[]", item);
          });
          formData.append("id", this.newRecord.id);
          formData.append("authority_id", this.newRecord.authority_id);
          formData.append("course_date", this.newRecord.course_date);
          formData.append("year", array[0]);
          // formData.append('user_id', this.newRecord.user_id);
          formData.append("topic", this.newRecord.topic);
          formData.append(
            "course_organization_id",
            this.newRecord.course_organization_id
          );
          formData.append(
            "ethics_compliance",
            this.newRecord.ethics_compliance
          );
          formData.append(
            "relevant_topic",
            this.newRecord.relevant_topic
          );
          formData.append("counted_hours", this.newRecord.counted_hours);
          // formData.append('file', this.newRecord.file);
          store
            .dispatch("app-user/addNewCourseRecord", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.show = false;
              this.resetForm();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleEdit(item) {
      this.id = item.id;
      this.approveTemplate = { ...item };
      console.log(this.approveTemplate);
    },
    onSubmit(id) {
      this.$swal({
        title: "Are you sure?",
        showCancelButton: true,
        confirmButtonText: "Save",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let data = {
            id: id,
            approved_bit: this.approveTemplate.approved_bit,
            topic: this.approveTemplate.topic,
            counted_hours: this.approveTemplate.counted_hours,
            ethics_compliance: this.approveTemplate.ethics_compliance,
            relevant_topic: this.approveTemplate.relevant_topic,
            remark: this.approveTemplate.remark,
          };
          console.log(data);
          this.loading = false;
          store
            .dispatch("app-user/updateApprovalBit", data)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    courseData: {
      type: Array,
      required: true,
    },
    totalHours: {
      type: Number,
      required: true,
    },
  },
  computed: {
    filteredData() {
      if (!this.dateFrom && !this.dateTo) {
        return this.courseData;
      }
      const filtered = this.courseData.filter(item => {
        const date = item.course_date;
        console.log(date)
        return (date >= this.dateFrom) && (date <= this.dateTo);
      });
      console.log(filtered)
      return filtered;
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const { resolveUserRoleVariant } = useUsersList();
    // let fakeSortYear = ['2022', '2021', '2020', '2019']
    // fakeSortYear = ref(fakeSortYear);
    let getYear = new Date().getFullYear();
    const year = getYear;
    const newRecord = ref({
      id: 0,
      authority_id: "",
      course_date: null,
      user_id: [props.userData.id],
      topic: "",
      course_organization_id: "",
      ethics_compliance: "",
      relevant_topic: "",
      counted_hours: "",
      file: [],
    });

    const uploadUrl = ref(
      process.env.VUE_APP_SERVICE_URL + "/api/project/uploadFile"
    );
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const addrecordmodeltitle = ref(
      "Add new record for " + props.userData.name
    );

    const resetRecordData = () => {
      newRecord = JSON.parse(JSON.stringify({ id: 0 }));
    };
    // Table Handlers
    const tableColumns = [
      { key: "approved_bit", label: "Approved", sortable: true },
      { key: "course_date", label: "date", sortable: true },
      { key: "topic", label: "topic" },
      { key: "course_organization_name", label: "Organiser" },
      { key: "ethics_compliance", label: "ethics & compliance topic related" },
      { key: "relevant_topic", label: "Topics directly relevant to the regulated activities" },
      { key: "counted_hours", label: "counted of cpt" },
      { key: "actions" },
    ];
    const resetData = () => {
      emit("refetch-data");
    };
    const userOptions = ref([]);
    const licenseAuthorityOptions = ref([]);
    const licenseAuthorityOptionsTab = ref([]);
    const organizationOptions = ref([]);
    const yearOptions = ref([]);
    const progress = ref([]);
    const min_hours = ref();
    const min_ethics_hours = ref();
    const completedHours = ref();
    const selectedYear = ref([]);
    const selectedCourse = ref([]);
    const trainingName = ref("CPT");
    const ethicsBool = ref(false);
    const relevantTopicBool = ref(0);
    const yesNoOptions = [
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 0,
        name: "No",
      },
    ];

    // const title = 1;
    const fetchUserData = () => {
      store
        .dispatch("app-user/fetchUsers")
        .then((response) => {
          userOptions.value = response.data.users;
        })
        .catch((error) => {
          // if (error.response.status === 404) {
          userOptions.value = undefined;
          console.log(error);
          // }
        });
    };

    const fetchProgress = () => {
      store
        .dispatch("app-user/fetchProgress", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          console.log("fetchProgress response", response, year);
          if (response.data.length > 0) {
            filterYear(year);
            progress.value = response.data;
            min_hours.value = response.data[0].min_hours;
            min_ethics_hours.value = response.data[0].min_ethics_hours;
          } else {
            filterYear(year);
            this.min_hours = "N/A";
            this.min_ethics_hours = "N/A";
            this.completedHours = 0;
          }
        })
        .catch((error) => {
          console.log("fetchProgress error", error);
          progress.value = undefined;
        });
    };

    const filterYear = (year) => {
      selectedYear.value = progress.value.filter((x) => {
        if (year.course_year == undefined) {
          return x.course_year == year;
        }
        return x.course_year == year.course_year;
      });
      console.log(selectedYear, year);
      filterName(trainingName);
    };

    const filterName = (trainingName) => {
      selectedCourse.value = selectedYear.value.filter((x) => {
        store.dispatch("app-user/getCourseList").then((response) => {
          console.log("response get setting list", response);

          response.data.course_settings.map((setting) => {
            if (x.course_setting_id == setting.id) {
              x.training_name = setting.training_name;
            }
          });
        });
        if (trainingName.value == undefined) {
          return x.training_name == trainingName;
        }
        return x.training_name == trainingName.value;
      });
      console.log("filterName", selectedCourse.value);
      if (selectedCourse.value.length == 0) {
        console.log(year);
        let years = year.course_year == undefined ? year : year.course_year;
        let id = 0;
        if (trainingName.value == undefined) {
          if (trainingName == "CPT") {
            id = 1;
          } else if (trainingName == "CPD") {
            id = 2;
          }
        } else {
          if (trainingName.value == "CPT") {
            id = 1;
          } else if (trainingName.value == "CPD") {
            id = 2;
          }
        }
        store
          .dispatch("app-user/fetchHours", { year: years, authority_id: id })
          .then((response) => {
            console.log(response);
            const hoursResponse = response.data.results.filter(x => x.staff_id == router.currentRoute.params.id);
            console.log('hoursResponse', hoursResponse);
            min_hours.value = hoursResponse[0].min_hours;
            ethicsBool.value = false;
          })
          .catch((error) => {
            min_hours.value = 0;
          });
        completedHours.value = 0;
        console.log("zero");
        return;
      }
      filterMinHour(
        selectedCourse.value[0].min_hours,
        selectedCourse.value[0].training_name,
        selectedCourse.value
      );
    };

    const filterMinHour = (hours, name, year) => {
      min_hours.value = hours;
      let hoursArray = [];
      let minEthicsHoursArray = [];
      let relevantHoursArray = [];
      min_ethics_hours.value = year[0].min_ethics_hours;
      year.map((x) => {
        if (x.relevant_topic == 1) {
          relevantHoursArray.push(x.counted_hours);
          // relevantTopicBool.value = true
        }
        if (x.approved_bit == 1) {
          hoursArray.push(x.counted_hours);
        }
        if (x.approved_bit == 1 && x.ethics_compliance == 1) {
          console.log(x.counted_hours);
          minEthicsHoursArray.push(x.counted_hours);
        }
      });
      completedHours.value = hoursArray.reduce((x, y) => x + y, 0);
      let totalEthicsHours = minEthicsHoursArray.reduce((x, y) => x + y, 0);
      relevantTopicBool.value = relevantHoursArray.reduce((x, y) => x + y, 0);
      console.log("totalEthicsHours", totalEthicsHours);
      console.log("totalRelevantHours", totalRelevantHours);
      console.log("min_ethics_hours", min_ethics_hours);
      console.log("min_ethics_hours.value", min_ethics_hours.value);

      if (totalEthicsHours >= min_ethics_hours.value) {
        console.log("value true");
        ethicsBool.value = true;
      } else {
        console.log("value false");
        ethicsBool.value = false;
      }
      console.log(
        hours,
        name,
        year,
        hoursArray,
        completedHours,
        ethicsBool.value,
        min_ethics_hours.value,
        totalEthicsHours,
        totalRelevantHours
      );
    };

    /**
     * MADE BY CHATGPT
     */

    // const fetchProgress = async () => {
    //   try {
    //     const response = await store.dispatch("app-user/fetchProgress", {
    //       id: router.currentRoute.params.id,
    //     });
    //     progress.value = response.data;
    //     min_hours.value = response.data[0].min_hours;
    //     min_ethics_hours.value = response.data[0].min_ethics_hours;
    //     filterYear(year);
    //   } catch (error) {
    //     console.log("fetchProgress error", error);
    //     progress.value = undefined;
    //   }
    // };

    // const filterYear = (year) => {
    //   const filteredYear = progress.value.filter((x) => {
    //     if (year.course_year == undefined) {
    //       return x.course_year == year;
    //     }
    //     return x.course_year == year.course_year;
    //   });
    //   selectedYear.value = filteredYear;
    //   filterName(trainingName);
    // };

    // const filterName = async (trainingName) => {
    //   const courseListResponse = await store.dispatch("app-user/getCourseList");
    //   const { course_settings } = courseListResponse.data;
    //   const filteredCourse = selectedYear.value.filter((x) => {
    //     for (const setting of course_settings) {
    //       if (x.course_setting_id === setting.id) {
    //         x.training_name = setting.training_name;
    //         break;
    //       }
    //     }
    //     if (trainingName.value == undefined) {
    //       return x.training_name == trainingName;
    //     }
    //     return x.training_name == trainingName.value;
    //   });
    //   selectedCourse.value = filteredCourse;
    //   if (selectedCourse.value.length === 0) {
    //     let years = year.course_year == undefined ? year : year.course_year;
    //     let id = 0;
    //     if (trainingName.value == undefined) {
    //       if (trainingName == "CPT") {
    //         id = 1;
    //       } else if (trainingName == "CPD") {
    //         id = 2;
    //       }
    //     } else {
    //       if (trainingName.value == "CPT") {
    //         id = 1;
    //       } else if (trainingName.value == "CPD") {
    //         id = 2;
    //       }
    //     }
    //     try {
    //       const response = await store.dispatch("app-user/fetchHours", {
    //         year: years,
    //         authority_id: id,
    //       });
    //       min_hours.value = response.data.results[0].min_hours;
    //       ethicsBool.value = false;
    //     } catch (error) {
    //       min_hours.value = 0;
    //     }
    //     completedHours.value = 0;
    //     return;
    //   }
    //   filterMinHour(
    //     selectedCourse.value[0].min_hours,
    //     selectedCourse.value[0].training_name,
    //     selectedCourse.value
    //   );
    // };

    // const filterMinHour = (hours, name, year) => {
    //   min_hours.value = hours;
    //   const hoursArray = [];
    //   const minEthicsHoursArray = [];
    //   const relevantHoursArray = [];
    //   min_ethics_hours.value = year[0].min_ethics_hours;

    //   year.forEach((x) => {
    //     if (x.relevant_topic == 1) {
    //       relevantHoursArray.push(x.counted_hours);
    //     }
    //     if (x.approved_bit == 1) {
    //       hoursArray.push(x.counted_hours);
    //     }
    //     if (x.approved_bit == 1 && x.ethics_compliance == 1) {
    //       minEthicsHoursArray.push(x.counted_hours);
    //     }
    //   });

    //   completedHours.value = hoursArray.reduce((x, y) => x + y, 0);
    //   const totalEthicsHours = minEthicsHoursArray.reduce((x, y) => x + y, 0);
    //   relevantTopicBool.value = relevantHoursArray.reduce((x, y) => x + y, 0);

    //   if (totalEthicsHours >= min_ethics_hours.value) {
    //     ethicsBool.value = true;
    //   } else {
    //     ethicsBool.value = false;
    //   }
    // };

    /**
     * MADE BY CHATGPT
     */


    const fetchOptions = () => {
      store
        .dispatch("app/fetchOptionList", {
          license_authority: true,
          course_organization: true,
          course_year: true,
        })
        .then((response) => {
          console.log(response);
          licenseAuthorityOptionsTab.value = [
            ...response.data.license_authorities,
          ];
          licenseAuthorityOptions.value = response.data.license_authorities;
          licenseAuthorityOptions.value.unshift({
            id: "b",
            authority_name: "SFC & IA",
            training_name: "Both",
          });
          organizationOptions.value = response.data.course_organizations;
          yearOptions.value = response.data.course_years;
        })
        .catch((error) => {
          // if (error.response.status === 404) {
          licenseAuthorityOptions.value = undefined;
          licenseAuthorityOptionsTab.value = undefined;
          organizationOptions.value = undefined;
          yearOptions.value = undefined;
          console.log(error);
          // }
        });
    };

    const { refFormObserver, getValidationState } =
      formValidation(resetRecordData);

    onMounted(() => {
      console.log("UserViewUserInfoCard", props);
      fetchUserData();
      fetchOptions();
      fetchProgress();
      // filterYear()
    });
    const approved_options = [
      {
        id: -1,
        name: "Rejected",
      },
      {
        id: 1,
        name: "Approved",
      },
      {
        id: 0,
        name: "Pending Approval",
      },
    ];

    const e_c = [
      {
        id: 1,
        name: "Yes",
      },
      {
        id: 0,
        name: "No",
      },
    ];
    return {
      avatarText,
      resolveUserRoleVariant,
      yearOptions,
      min_hours,
      min_ethics_hours,
      ethicsBool,
      relevantTopicBool,
      year,
      tableColumns,
      approved_options,
      e_c,
      loading,
      userOptions,
      yesNoOptions,
      licenseAuthorityOptions,
      licenseAuthorityOptionsTab,
      organizationOptions,
      resetData,
      refFormObserver,
      getValidationState,
      newRecord,
      addrecordmodeltitle,
      // title,
      filterYear,
      filterName,
      completedHours,
      selectedYear,
      trainingName,
      ability,
      uploadUrl,
      headerObj,
    };
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@/assets/scss/variables/_variables.scss";

#userTableLeft,
#userTableRight {
  width: 80%;
}

@media only screen and (max-width: 600px) {
  #userTableRight {
    width: 100%;
  }
}

.parent>* {
  padding-top: 7px;
}

.m-0 {
  padding-left: 5px;
}

.pointed-li {
  padding-left: 1em;
  position: relative;

  &:before {
    content: "\2022";
    position: absolute;
    top: 1px;
    color: $primary-color-3;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: 24px;
    margin-left: -15px;
  }
}
</style>